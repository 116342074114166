<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-debounce:300ms="getFiltered"
        placeholder="Stage"
        :loading="loading"
        :disabled="disabled"
        :required="required"
        :icon="$tc('icons.stages')"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        v-model="searchTerm"
        v-on:icon-right-click="clear()"
    ></b-input>
    <b-autocomplete
        :disabled="disabled"
        :required="required"
        ref="autocomplete"
        v-if="type==='autocomplete'"
        v-debounce:300ms="getFiltered"
        :data="searchResponse"
        autocomplete="off"
        :loading="loading"
        :icon="$tc('icons.stages')"
        v-model="searchTerm"
        placeholder="Stage"
        :clear-on-select="false"
        :custom-formatter="thing=> name(thing)"
        @select="option => select(option)"
        :clearable="true"
    >
      <template slot-scope="props">
        <p>{{ props.option.name }} </p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import Stage from "@/models/Stage";

export default {
  name: "StagesFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null
    };
  },
  props: {
    clearFlip:{
      type:Boolean,
      default(){
        return false
      }
    },
    disabled:{
      type:Boolean,
      default() {
        return false
      }
    },   required:{
      type:Boolean,
      default() {
        return false
      }
    },
    selected_stage_id: {
      type: Number, required: false
    },
    type: {
      type: String, default() {
        return 'filter'
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    },
    phase_id: {
      type: Number, default() {
        return null;
      }
    },year: {
      type: Number, default() {
        return this.$store.state.dates.year
      }
    },
  },
  watch: {
    clearFlip(val){
      if (val===true) {
        this.clear()
      }
    },
    selected_stage_id(newValue) {
      if (newValue !== null) {
        Stage.FetchById(this.selected_stage_id).then(({entities}) => {
          this.searchResponse = entities.stages
          this.$refs.autocomplete.setSelected(entities.stages[0])

        })
      }
    }
  },
  mounted() {
    if (this.selected_stage_id) {
      Stage.FetchById(this.selected_stage_id).then(({entities}) => {
        this.searchResponse = entities.stages
        this.$refs.autocomplete.setSelected(entities.stages[0])

      })
    }
  },
  methods: {
    name(thing) {
      return `${thing.name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.$emit('selected', option)
      }
    },

    clear() {
      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {

      if (text.length > 0) {
        this.loading = true;

        Stage.FetchAll({page: 1, limit: 15}, {
          ...{search: text}, ...(this.campus_id ? {
            campus_id: this.campus_id
          } : {}), ...(this.phase_id ? {
            phase_id: this.phase_id
          } : {}),year:this.year
        }).then((result) => {
          this.loading = false;

          if (result.entities) {
            this.searchResponse = result.entities.stages;
            if (this.type === 'filter') {
              this.$emit('filtered', {result: result, term: this.searchTerm})
            }
          } else {
            this.$emit('filtered', {term: this.searchTerm})

            this.searchResponse = [];
          }
        });
      } else {
        this.clear()
      }
    }
  }
}
</script>

<style scoped>

</style>
