<template>
  <div ref="announcement_container" class="tile-panel box is-relative">
    <div class="has-background-white header-container pt-4" :class="{'pl-5':$store.getters['size/viewType'] !=='tablet'}">
      <header class="has-background-white is-clickable "
              @click.prevent="$emit('toggleExpand')">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <h3 class="title is-capitalized is-size-4-touch">
                Announcements
              </h3></div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <a
                  aria-label="more options"
                  class="card-header-icon has-text-primary"
                  href="#"
              >
<!--                <b-icon :icon="isExpanded?$tc('icons.menu-up'):$tc('icons.menu-down')"/>-->
              </a></div>
          </div>
        </div>

      </header>
      <transition mode="in-out" name="fade">
<!--        <hr class="card-divider mt-1 mb-1">-->
      </transition>
      <transition mode="in-out" name="fade">
        <div class="pb-4 px-2 filter-container">
          <b-field grouped position="is-right">




            <!--          <b-datepicker :size="'is-small'" v-model="$store.state.announcements.date">-->
            <!--          </b-datepicker>-->

            <b-field>
              <UsersFilter v-if="canAdministrate && inPage" :dropdown-direction="'bottom'"
                           :placeholder="'Creator'"
                           :type="'autocomplete'"
                           :without_roles="['learner','guardian']" @cleared="creator=null"
                           @selected="user=>creator=user.id"
              ></UsersFilter>
              <b-button v-if="canCreate && inPage" :icon-right="$tc('icons.create')" type="is-primary"
                        @click="addAnnouncement()">
                Add Announcement
              </b-button>


            </b-field>
            <b-field>
              <b-dropdown ref="announcementFilters"
                          :close-on-click="false"
                          :position="'is-bottom-left'"
                          aria-role="list"
                          :mobile-modal="false"
              >

                <template #trigger>
                  <b-button
                      :icon-right="$tc('icons.filters')"
                      class="has-text-not-underlined has-text-grey" :class="{'has-text-primary':$store.state.announcements.campus_filter!==null||$store.state.announcements.selectedAnnouncementType!=='All'}"  outlined
                      type="is-ghost"
                  ><span class="subtitle is-size-6 has-text-not-underlined">Filter</span></b-button>
                </template>

                <b-dropdown-item class="px-2">
                  <b-field expanded>
                    <b-datepicker v-model="$store.state.announcements.date" :icon="$tc('icons.calendar')"
                                  :mobile-native="false"
                                  append-to-body
                                  expanded
                                  :position="'is-bottom-left'"

                    >

                    </b-datepicker>
                  </b-field>
                </b-dropdown-item>
                <template v-if="showCampusFilter">
                  <b-dropdown-item :focusable="false" custom>

                    <p class="title is-size-6">
                      Campus
                    </p>
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem"
                                   custom
                  >
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item"><h3>All Campuses</h3>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <b-radio v-model="$store.state.announcements.campus_filter" :native-value="null"
                                   name="campus_id"></b-radio>
                        </div>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item v-for="campus in campuses" :key="campus.id"
                                   aria-role="listitem"
                                   custom>
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item"><h3>{{ campus.name }}</h3>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <b-radio v-model="$store.state.announcements.campus_filter" :native-value="campus.id"
                                   name="campus_id"></b-radio>
                        </div>
                      </div>
                    </div>

                  </b-dropdown-item>
                </template>
                <b-dropdown-item :focusable="false" custom>

                  <p class="title is-size-6">
                    Announcement Type
                  </p>
                </b-dropdown-item>
                <b-dropdown-item aria-role="listitem"
                                 custom
                >
                  <div class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item"><h3>All Announcements</h3>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <b-radio v-model="$store.state.announcements.selectedAnnouncementType" :native-value="'All'"
                                 name="announcement_type"></b-radio>
                      </div>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                                 aria-role="listitem"
                                 custom>
                  <div v-for="(type,index) of filteredFilters"
                       :key="index + 'announcement_type'" class="level is-mobile">
                    <div class="level-left">
                      <div class="level-item"><h3>{{ type.name }}</h3>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <b-radio v-model="$store.state.announcements.selectedAnnouncementType" :native-value="type.value"
                                 name="announcement_type"></b-radio>
                      </div>
                    </div>
                  </div>

                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </b-field>
        </div>
      </transition>
    </div>

    <transition mode="in-out" name="fade">
      <PerfectScrollbarWrapper ref="announcementsScrollWrapper" class="items-container " infinite-wrapper>

        <div class="card-content  has-background-white  py-0 px-1">

          <div>
            <div v-if="announcements.length===0 && pinnedAnnouncements.length===0">
              <p>No announcements</p>
            </div>
            <div v-else>
              <template v-if="!inPage">
                <div
                    v-for="announcement in pinnedAnnouncements"
                    :key="announcement.id"
                >
                  <AnnouncementsListItem
                      :announcement="announcement"
                      :crop-length="cropLength"
                      :permissions="{
                       can_delete: canDelete|| (parseInt(announcement.creator_id) === $store.state.user.id),
                       can_edit: canEdit || (parseInt(announcement.creator_id) === $store.state.user.id)
                       }"
                      :show-dates="inPage"
                      :show-dropdown="inPage"
                      :show-start="!inPage"
                      :in-page="inPage"
                      @clicked="openAnnouncementModal(announcement)"
                  ></AnnouncementsListItem>
                </div>
              </template>
              <div
                  v-for="announcement in announcements"
                  :key="announcement.id"
              >
                <AnnouncementsListItem
                    :announcement="announcement"
                    :crop-length="cropLength"
                    :in-page="inPage"

                    :permissions="{
                                       can_delete: canDelete|| (parseInt(announcement.creator_id) === $store.state.user.id),
                                       can_edit: canEdit || (parseInt(announcement.creator_id) === $store.state.user.id)
                                     }"
                    :show-dates="inPage"
                    :show-dropdown="inPage"
                    :show-start="!inPage"
                    @clicked="openAnnouncementModal(announcement)"

                ></AnnouncementsListItem>
              </div>

            </div>


          </div>


        </div>
        <infinite-loading v-if="!inPage" :identifier="infiniteId"
                          forceUseInfiniteWrapper
                          spinner="waveDots"
                          @infinite="infiniteHandler"
        >
          <template #no-more>
            <span>No more announcements</span>
          </template>
        </infinite-loading>

      </PerfectScrollbarWrapper>
    </transition>

    <b-loading v-if="usePagination" :active.sync="$store.state.announcements.loading" :can-cancel="false"
               :is-full-page="false"></b-loading>
    <b-pagination
        v-if="usePagination"
        :current="page"
        :loading="$store.state.announcements.loading"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="margin-top has-background-white mb-0 px-2 panel-pagination"
        v-on:change="setPage"
    ></b-pagination>
    <footer v-if="!inPage && !isMobile" class="panel-footer is-clickable pb-2" @click.prevent="$emit('toggleExpand')">
      <hr class="mb-3">
      <p class="has-text-centered">Show {{ $store.state.calendar.expandedPanels.includes('announcements')? 'less':'more' }}</p>
    </footer>
  </div>

</template>

<script>

import AnnouncementsListItem from "@/components/announcements/AnnouncementsListItem";
import Announcement from "@/models/Announcement";
import AnnouncementForm from "@/components/announcements/AnnouncementForm";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import Campus from "@/models/Campus";
import InfiniteLoading from "vue-infinite-loading";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

//When is an announcement without a model name being created?
//Show start and end time?
//Permissions for audience types?
export default {
  name: "AnnouncementsPanel",
  components: {
    AnnouncementsListItem,
    UsersFilter,
    InfiniteLoading,
    PerfectScrollbarWrapper
  },
  data() {
    return {
      page: 1,
      meta: Object,
      loaded: false,
      announcementsHold: null,
      creator: null,
      infiniteId: new Date(),


    }
  },
  props: {
    limit: {
      type: Number,
      default() {
        return 10
      }
    },
    cropLength: {
      type: Number,
      default() {
        return 50
      }
    },
    usePagination: {
      type: Boolean, default() {
        return this.inPage
      }
    }, isExpanded: {
      type: Boolean, default() {
        return false
      }
    },isMobile: {
      type: Boolean, default() {
        return false
      }
    }, inPage: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    },
    canAdministrate: {
      type: Boolean, default() {
        return false
      }
    },

  },
  watch: {
    isExpanded() {
      this.page = 1
    },
    filters() {
      this.setPage(1, false, true)
    }
  },
  methods: {

    infiniteHandler($state) {
      return this.setPage(this.page).then(data => {
        if (data.length > 0) {
          this.page++;
          // this.twiddle();
          $state.loaded();
          return;
        }
        $state.complete();
      });
    },

    addAnnouncement() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: false,
          inModal: true,
        },
        component: AnnouncementForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x']
      });

    },
    openAnnouncementModal(announcement) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          showDates: this.inPage,
          showStart: !this.inPage,
          permissions: {
            can_delete: this.canDelete || (parseInt(announcement.creator_id) === this.$store.state.user.id),
            can_edit: this.canEdit || (parseInt(announcement.creator_id) === this.$store.state.user.id)
          },
          inModal: true,
          announcement: announcement
        },
        component: AnnouncementsListItem,
        fullScreen: false,
        trapFocus: true,
      })
    },
    fetchPinnedAnnouncements() {
      Announcement.FetchAllByUser(
          {
            page: this.page,
            limit: this.limit,
          },
          {...this.filters, ...{is_pinned: 1}}, ['creator', 'media'], this.$store.state.user.id
      )
    },
    setPage(pageNumber, initial = false, reload = false) {
      this.$store.state.announcements.loading = true;
      this.announcementsHold = this.announcements;
      if (this.inPage || reload) {
        Announcement.deleteAll();
      }
      if (!this.inPage) {
        this.fetchPinnedAnnouncements()
      }
      if (reload) {
        this.infiniteId = new Date
        this.$refs.announcementsScrollWrapper.psUpdate()
      }
      this.page = pageNumber;
      return (this.inPage ? Announcement.FetchAll({
            page: this.page,
            limit: this.limit,
          },
          this.filters, ['creator', 'media']
      ) : Announcement.FetchAllByUser(
          {
            page: this.page,
            limit: this.limit,
          },
          {...this.filters, ...{is_pinned: 0}}, ['creator', 'media'], this.$store.state.user.id
      )).then(
          ({
             response: {
               data: {meta, data},
             },
           }) => {
            this.meta = meta;
            this.$store.state.announcements.loading = false;
            this.announcementsHold = null;
            if (reload) {
              this.$refs.announcementsScrollWrapper.psUpdate()
              this.$refs.announcementsScrollWrapper.scrollToTop()
            }
            if (!initial) {
              this.$cookies.set(
                  `announcement_filters`,
                  {
                    page: this.page,
                  },
                  "1d"
              );
            }
            return Promise.resolve(data)

          }
      );
    },

  },
  computed: {
    filteredFilters() {
      return this.$store.state.announcements.model_types.filter(model => {
        return (this.$store.state.auth.type === 'staff' && !this.inPage) ? model.filter_availability.includes(1) : true
      })
    },
    showCampusFilter() {
      // return this.$store.state.auth.type !== 'guardian'
      return true
    },
    campuses() {
      return Campus.query().get()
    },
    filters() {
      return {

        ...(this.$store.getters['announcements/formattedDate']
            ? {
              date: this.$store.getters['announcements/formattedDate'],
            }
            : {}),
        ...(this.$store.state.announcements.selectedAnnouncementType !== 'All'
            ? {
              type: this.$store.state.announcements.selectedAnnouncementType,
            }
            : {}),
        ...(this.$store.state.announcements.campus_filter !== null
            ? {
              campus_id: this.$store.state.announcements.campus_filter,
            }
            : {})
        , ...(this.canAdministrate
            ? {
              creator: this.creator,
            }
            : {})
      }
    },
    pinnedAnnouncements() {
      return Announcement.query()
          .with("creator")
          .with('campus')
          .with('media')
          .with('models')
          .with('departments')
          .where('is_pinned', 1)
          .orderBy("start_date", "asc")
          .orderBy('end_date', "asc")
          .get();
    },
    announcements() {
      if (this.announcementsHold === null) {
        return Announcement.query()
            .with("creator")
            .with('campus')
            .with('media')
            .with('models')
            .with('departments')
            .where(announcement => {
              return this.inPage ? true : announcement.is_pinned === 0
            })
            .orderBy("start_date", "asc")
            .orderBy('end_date', "asc")
            .get();


      } else {
        return this.announcementsHold;
      }

    },
  },
  mounted() {
    Announcement.deleteAll()
    if (this.$cookies.isKey(`announcement_filters`) && !this.inPage) {
      // let filters = JSON.parse(this.$cookie.get(`announcement_filters`));
      this.page = 1;
      this.$cookies.remove(`announcement_filters`);
    }

    this.setPage(this.page, true)

  }
}
</script>
