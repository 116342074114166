<template>
  <div class="listitem announcement-item ">
    <article class="media mb-2">
      <!--      <figure class="media-left">-->
      <!--        <p class="has-text-centered">-->
      <!--          <b-tooltip append-to-body :label="announcement.models[0].model_type" position="is-top">-->
      <!--            <b-icon class="icon rounded is-medium box is-64x64"-->
      <!--                    :icon="$tc(`icons.${$store.state.announcements.model_types.find(type=>type.value===announcement.models[0].model_type).name}`)"></b-icon>-->
      <!--          </b-tooltip>-->
      <!--        </p>-->
      <!--        Future mark read function perhaps -->
      <!--        <p class="has-text-centered mt-2">-->
      <!--          <b-field>-->
      <!--            <b-tooltip-->
      <!--                :label="activity.is_complete!=1?'Mark as complete':'Mark as incomplete'"-->
      <!--                position="is-top"-->
      <!--            >-->
      <!--              <b-button-->
      <!--                  :loading="loadingActivity"-->
      <!--                  :type="{'is-success':activity.is_complete==1}"-->
      <!--                  class="is-rounded"-->
      <!--                  @click.prevent="completeActivity()"-->
      <!--              >-->
      <!--                <b-icon :icon="$tc('icons.tick')"></b-icon>-->
      <!--              </b-button>-->
      <!--            </b-tooltip>-->
      <!--          </b-field>-->
      <!--        </p>-->
      <!--      </figure>-->
      <div :class="{'py-1':!inModal}" class="box large-box  media-content is-relative has-pointer pb-4"
           @click="handleClick()">
        <div class="columns is-fullwidth is-vcentered is-mobile">
          <div v-if="announcement.is_pinned" class="column is-narrow pr-0">
            <div class=" is-pinned"></div>

          </div>
          <div :class="{'ml-3 ':!announcement.is_pinned, 'pl-0':announcement.is_pinned}" class="content column ">
            <div :class="{'mb-0':!inModal}" class="level is-mobile has-dropdown">
              <div class="level-left">
                <div class="level-item">
                  <span
                      :class="[$store.state.announcements.model_types.find(type=>type.value===announcement.models[0].model_type).name]"
                      class="announcement-type-stripe"></span>
                </div>
                <div class="level-item">
                  <p v-if="showDates" :class="{'mb-2':!inModal}" class="subtitle is-7">
                    Created by {{ announcement.creator.first_name }} {{ announcement.creator.last_name }}. Active
                    {{
                      formatDate(announcement.start_date)

                    }} to
                    {{ formatDate(announcement.end_date) }}</p>
                  <p v-if="showStart" class="subtitle is-7 mb-1">

                    {{
                      formatDate(announcement.start_date)

                    }} </p>

                </div>
              </div>
              <div v-if="showDropdown&&(permissions.can_delete||permissions.can_edit)" class="level-right">
                <div class="level-item">
                  <b-dropdown v-if="permissions.can_delete||permissions.can_edit" :append-to-body="inPage"
                              :mobile-modal="false"
                              aria-role="list"
                              class="listitem-dropdown"
                              position="is-bottom-left"
                              @click.native.stop>
                    <b-button slot="trigger" :icon-right="$tc('icons.more')" class=" is-rounded" type="is-ghost">
                    </b-button>

                    <b-dropdown-item v-if="permissions.can_edit" aria-role="listitem" @click="startEdit">Edit
                    </b-dropdown-item>
                    <b-dropdown-item v-if="permissions.can_delete" aria-role="listitem"
                                     @click="startDelete(announcement)">
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>

                </div>
              </div>
            </div>
            <h3 :class="{'mb-0':!inModal}" class="is-capitalized is-size-5 mt-0" v-html="announcement.title"></h3>


            <div v-if="!inModal" :class="{'mb-1':media.length>0}" class="content announcement-content is-size-7"
                 v-html="announcement.content"></div>
            <div v-else class="content" v-html="announcement.content"></div>
            <b-field v-if="media.length>0">
              <b-taglist><a v-for="file in media" :key="file.id" :href="file.temporary_url" class="tag"
                            download target="_blank">{{ file.caption }}</a></b-taglist>

            </b-field>
          </div>

        </div>
      </div>
    </article>

  </div>
</template>

<script>
import Announcement from "@/models/Announcement";
import AnnouncementForm from "@/components/announcements/AnnouncementForm";
import clip from "text-clipper";
import {format} from "date-fns";
import Media from "@/models/Media";


export default {
  name: "AnnouncementsListItem",
  props: {
    cropLength: {
      type: Number,
      default() {
        return 50
      }
    },
    inModal: {
      type: Boolean,
      default() {
        return false
      }
    }
    , showDates: {
      type: Boolean,
      default() {
        return false
      }
    }, showStart: {
      type: Boolean,
      default() {
        return true
      }
    },
    showDropdown: {
      type: Boolean,
      default() {
        return true
      }
    }, inPage: {
      type: Boolean,
      default() {
        return false
      }
    },
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
      }),
    },
    announcement: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      loading: false,
      expanded: false

    };
  },
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()

    }
  },
  computed: {
    media() {
      return Media.query()
          .where('mediable_type', 'announcements')
          .where('mediable_id', this.announcement.id).get()
    }
  },
  methods: {
    formatDate(date) {
      if (date === null) {
        return null
      }
      return format(new Date(date), "EEEE, do 'of' MMMM yyyy")
    },
    handleClick() {
      if (this.inModal) {
        return null
      }
      this.$emit('clicked')
    },
    clipContent(text) {
      if (text) {
        return clip(text, this.cropLength, {
          html: true,
        });
      }
      return ""
    },
    startEdit() {
      if (this.permissions.can_edit) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            announcement: this.announcement,
            edit: true,
            inModal: true,
          },
          component: AnnouncementForm,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x']
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteAnnouncement(announcement) {
      this.$store.dispatch("loader/show");
      Announcement.Delete(announcement.id).then(
          () => {
            this.$buefy.snackbar.open('Announcement deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },
    startDelete(announcement) {
      if (this.permissions.can_delete) {
        this.$buefy.dialog.confirm({
          title: "Deleting announcement",
          message:
              "Are you sure you want to <b>delete</b> this announcement? This action cannot be undone.",
          confirmText: "Delete Announcement",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteAnnouncement(announcement),
        });
      } else {
        this.$store.dispatch("toast/createToast");

      }
    },
  },
};
</script>
