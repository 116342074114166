<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Announcement</p>
      </header>
      <PerfectScrollbarWrapper>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Start">
          <b-datepicker
              :years-range="[-2,2]" placeholder="Start" required expanded
              v-model="start"></b-datepicker>
        </b-field>
        <b-field expanded label="End">
          <b-datepicker
              :years-range="[-2,2]" placeholder="End" required expanded
              v-model="end"></b-datepicker>
        </b-field>
        <b-field label="Pin">
          <b-checkbox :true-value="1" :false-value="0" v-model="announcementObject.is_pinned">
Pin announcement to the top of the announcements list
          </b-checkbox>
        </b-field>
        <!--        <b-field label="Campus">-->
        <!--          <b-select :required="announcementObject.model_type!==null" v-model="announcementObject.campus_id"-->
        <!--                    placeholder="Campus">-->
        <!--            <option-->
        <!--                v-for="campus of campuses"-->
        <!--                :key="campus.id"-->
        <!--                :value="campus.id"-->
        <!--            >{{ campus.name }}-->
        <!--            </option>-->
        <!--          </b-select>-->
        <!--        </b-field>-->
        <b-field label="Title">
          <b-input
              v-model="announcementObject.title"
              placeholder="Announcement Title"
              required>
          </b-input>
        </b-field>
        <b-field style="overflow: hidden" label="Content">
          <ckeditor style="overflow: hidden"
                    :editor="editor"
                    v-model="announcementObject.content"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>
        <b-field label="Audience">
          <b-select required v-model="announcementObject.audience_id" placeholder="Audience">
            <option
                v-for="audience of $store.state.announcements.audiences"
                :key="audience.id"
                :value="audience.id"
            >{{ audience.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Announcement Types">
          <b-select required v-model="model_type"
                    placeholder="Type">
            <option
                v-for="(type,index) of announcementTypes"
                :key="index"
                :value="type.value"
            >
<!--              <b-icon :icon="$tc(`icons.${type.value}`)"/>-->
              {{ type.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="model_type==='HomeClass'||model_type==='Subject' || model_type==='Phase'||model_type==='Stage'"
                 label="Campus">
          <b-select
              @input="resetTargets"
              v-model="announcementObject.campus_id"
              placeholder="Campus">
            <option
                v-for="campus of campuses"
                :key="campus.id"
                :value="campus.id"
            >{{ campus.name }}
            </option>
          </b-select>
        </b-field>
        <b-field expanded :type="submitAttempted?'is-danger':''"
                 v-if="model_type!=='Global' && model_type!==null && (model_type!=='Department'&& announcementObject.audience_id!==3)"
                 label="Target"></b-field>

        <div v-for="(model,index) in announcementObject.models" class="my-2" :key="index+''+resetKey">
          <b-field grouped expanded>
            <b-field expanded>
              <PhasesFilter
                  v-if="model_type==='Phase'"
                  @selected="option=>model.model_id=option.id"
                  :type="'autocomplete'"
                  :selected_phase_id="model.model_id"
                  @cleared="model.model_id=null"
                  :campus_id="announcementObject.campus_id"></PhasesFilter>

              <SubjectsFilter v-if="model_type ==='Subject'"
                              :type="'autocomplete'"
                              :selected_subject_id="model.model_id"
                              @selected="option=>model.model_id=option.id"
                              @cleared="model.model_id=null"
                              :campus_id="announcementObject.campus_id"></SubjectsFilter>
              <HomeClassesFilter v-if="model_type ==='HomeClass'"
                                 :type="'autocomplete'"
                                 :selected_home_class_id="model.model_id"
                                 @selected="option=>model.model_id=option.id"
                                 @cleared="model.model_id=null"
                                 :campus_id="announcementObject.campus_id"></HomeClassesFilter>
              <StagesFilter v-if="model_type ==='Stage'"
                            :type="'autocomplete'"
                            :selected_stage_id="model.model_id"
                            @selected="option=>model.model_id=option.id"
                            @cleared="model.model_id=null"
                            :campus_id="announcementObject.campus_id"></StagesFilter>
              <b-select v-if="model_type==='Campus'" :required="model.model_id===null" v-model="model.model_id"
                        placeholder="Campus">
                <option
                    v-for="campus of specificFilteredCampuses(model.model_id)"
                    :key="campus.id"
                    :value="campus.id"
                >{{ campus.name }}
                </option>
              </b-select>

            </b-field>
            <b-field
                v-if="model_type!=='Global' && model_type!==null && model_type!=='Department'">
              <b-button
                  :icon-right="$tc('icons.delete')"
                  type="is-ghost"
                  class="has-text-danger"
                  @click="removeTarget(index)"
              >
              </b-button>
            </b-field>
          </b-field>
        </div>


        <b-field expanded
                 grouped
                 v-if="model_type!=='Global' && model_type!==null && model_type!=='Department'"
        >
          <span v-if="model_type ==='Campus'" class="control"><b-button
              type="is-primary"
              :disabled="model_type==='Campus'?filteredCampuses.length===0:false"

              @click="addAllCampuses"
              :icon-right="$tc('icons.create')"
          >
                      Add All
                    </b-button></span>
          <span class="control"><b-button
              :disabled="model_type==='Campus'?filteredCampuses.length===0:false"
              type="is-primary"
              @click="addTarget"
              :icon-right="$tc('icons.create')"
          >
                      Add
                    </b-button></span>
        </b-field>
        <b-field v-if="announcementObject.audience_id===1" label="Department(s)">

          <b-dropdown :max-height="300" scrollable v-model="announcementObject.filters.department_ids" multiple
                      aria-role="list">
            <b-button expanded type="is-primary" slot="trigger">
              <span>Select Department(s)</span>

              <b-icon :icon="$tc('icons.menu-down')"></b-icon>
            </b-button>
            <b-dropdown-item :class="{'is-active':announcementObject.filters.department_ids.length===0}"
                             @click.native.prevent="announcementObject.filters.department_ids=[]">All
            </b-dropdown-item>
            <b-dropdown-item
                v-for="department of departments"
                :key="department.id"
                :value="department.id"
            >
              <span>{{ department.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field v-if="edit" label="Creator">
          <UsersFilter
              :disabled="true"
              :selected_user_id="announcementObject.creator_id"
              :type="'autocomplete'"
          ></UsersFilter>
        </b-field>

        <b-field v-if="showMedia" expanded>
          <MediaPanel
              :submit_button="false"
              :submit_flip="submitMedia"
              @submitted="submitMedia=false;$emit('close')"
              :title="'Announcement Media'" :model="'announcements'"
              :has-tag="false"
              :model_id="parseInt(announcementObject.id)"
              :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
              :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
          ></MediaPanel>
        </b-field>
      </section>
      </PerfectScrollbarWrapper>
      <footer v-if="inModal" class="modal-card-foot">
        <b-button :loading="loading" type="is-primary" native-type="submit"
                  :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </b-button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit" value="Save">
          </b-button>
        </div>
        <div v-if="edit" class="control">
          <b-button :loading="loadingDelete" @click.prevent="startDelete" type="is-danger">
            Delete
          </b-button>
        </div>
      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
import Announcement from "@/models/Announcement";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import MediaPanel from "@/components/media/MediaPanel";
import PhasesFilter from "@/components/panelled-dash/PhasesFilter";
import SubjectsFilter from "@/components/panelled-dash/SubjectsFilter";
import HomeClassesFilter from "@/components/panelled-dash/HomeClassesFilter";
import Campus from "@/models/Campus";
import {addDays, format} from "date-fns";
import StagesFilter from "@/components/panelled-dash/StagesFilter";
import Department from "@/models/Department";
import PerfectScrollbarWrapper from '@/components/scrollbar/PerfectScrollbarWrapper';

export default {
  name: "AnnouncementForm",
  components: {StagesFilter, HomeClassesFilter, UsersFilter, MediaPanel, PhasesFilter, SubjectsFilter, PerfectScrollbarWrapper},
  data() {
    return {
      loading: false,
      loadingDelete: false,
      loaded: false,
      showMedia: false,
      submitAttempted: false,
      submitMedia: false,
      modelTypeHold: null,
      resetKey: 1,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The content of the announcement."},
      },
      announcementObject: {
        title: '',
        content: '',
        start_date: new Date(),
        end_date: new Date(),
        campus_id: this.$store.state.campus.selected_campus_id,
        audience_id: 1,
        is_pinned:0,
        models: null,
        filters: {
          department_ids: []
        }
      }
    }
  },
  methods: {
    resetTargets() {
      this.modelTypeHold = this.model_type
      this.announcementObject.models = null
      this.$set(this.announcementObject, 'models', [{model_type: this.model_type, model_id: null}])
      this.resetKey += this.resetKey
      this.modelTypeHold = null
    },
    specificFilteredCampuses(selectedCampusId) {
      if (this.model_type === 'Campus') {
        if (selectedCampusId === null) {
          return this.filteredCampuses
        }
        return Campus.query().where((campus) => {
          return !this.announcementObject.models.some(model => (model.model_id === campus.id)) || campus.id === selectedCampusId
        }).get()
      }
      return Campus.query().get()
    },
    addTarget() {
      this.announcementObject.models.push({model_type: this.model_type, model_id: null})
    },
    addAllCampuses() {
      this.announcementObject.models = this.campuses.map(campus => {
        return {model_type: 'Campus', model_id: campus.id}
      })
    },
    removeTarget(modelIndex) {
      this.announcementObject.models = this.announcementObject.models.filter((model, index) => {
        return index !== modelIndex
      })
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting announcement',
        confirmText: 'Delete Announcement',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this announcement?',
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      Announcement.Delete(this.announcementObject.id).then(() => {
        this.$buefy.snackbar.open(`Announcement deleted!`);
        this.$emit("close");
        this.$emit("deleted");
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      });

    },
    submit() {
      this.submitAttempted = true
      let submitObject = JSON.parse(JSON.stringify(this.announcementObject))
      if (this.edit || this.loaded) {
        submitObject.models.forEach(model => {
          model.announcement_id = this.announcementObject.id

        })
      }
      if (submitObject.audience_id !== 1) {
        submitObject.filters.department_ids = []
      }
      if (this.model_type === 'Global') {
        submitObject.campus_id = null
        submitObject.models = []
      }
      if (this.model_type === 'Campus') {
        submitObject.campus_id = submitObject.models[0].model_id
      }
      if (this.edit) {
        this.loading = true
        Announcement.Update(submitObject, true).then(() => {
          this.$buefy.snackbar.open(`Announcement updated!`)
          this.submitMedia = true
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.loading = true
        Announcement.Store(submitObject).then(({entities: {announcements}}) => {
          this.$buefy.snackbar.open(`Announcement created!`)
          this.announcementObject.id = announcements[0].id
          this.announcementObject.model_type = announcements[0].model_type
          this.showMedia = true
          this.loaded = true
          this.loading = false
          this.submitMedia = true
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },

  computed: {
    announcementTypes() {
      return this.$store.state.announcements.model_types.filter(type => {
        return type.availability.includes(this.announcementObject.audience_id)
      })
    },
    model_type: {
      get() {
        if (this.announcementObject.models === null || this.announcementObject.models === []) {
          return this.modelTypeHold === null ? null : this.modelTypeHold
        }
        return this.announcementObject.models[0].model_type
      },

      set(newValue) {
        this.announcementObject.models = [{model_type: newValue, model_id: null}]
      }
    },
    start: {
      set(newVal) {
        this.announcementObject.start_date = format(newVal, 'yyyy-MM-dd')
      },
      get() {
        if (this.announcementObject.start_date == null) {
          return null;
        }
        return new Date(this.announcementObject.start_date);
      },
    }, end: {
      set(newVal) {
        this.announcementObject.end_date = format(newVal, 'yyyy-MM-dd')
      },
      get() {
        if (this.announcementObject.end_date == null) {
          return null;
        }
        return new Date(this.announcementObject.end_date);
      },
    },
    filteredCampuses() {
      if (this.model_type === 'Campus') {
        return Campus.query().where((campus) => {
          return !this.announcementObject.models.some(model => model.model_id === campus.id)
        }).get()
      }
      return Campus.query().get()
    },
    campuses() {
      return Campus.query().get()
    }, departments() {
      return Department.query().orderBy('name').get()
    },
  },
  mounted() {
    this.loading = true
    Promise.all([Campus.FetchAll({page: 1, limit: 99}), Department.FetchAll()]).then(() => {
      if (this.edit) {
        this.announcementObject = JSON.parse(JSON.stringify(this.announcement))
        this.loaded = true
      } else {
        this.start = new Date()
        this.end = addDays(new Date(), 1)
      }
      if (this.announcementObject.model_type === null) {
        this.announcementObject.model_type = 'Global'
      }
      if (this.inModal) {
        this.makeModalLarge()

      }
      this.loading = false

    }).catch(err => {
      this.handleError(err)
      this.loading = false
    })
    this.showMedia = true
  },
  props: {
    announcement: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

